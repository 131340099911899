.property {
    --primary-color-eshi: #D14132;
    --primary-color-hover-eshi: #882900;
    --text-contrast-primary-eshi: white;
}

/*----------------------------------------BACKGROUND AND COLOR TEXT-----------------------------------------------*/

.bg-primary, .bg-primary:hover {
    background-color: var(--primary-color-eshi, #D14132);
    color: var(--text-contrast-primary-eshi, white);
}
.color-primary {
    color: var(--primary-color-eshi, #D14132);
}
.bg-primary-hover {
    background-color: var(--primary-color-eshi, #D14132);
    color: var(--text-contrast-primary-eshi, white);
}
.bg-primary-hover:hover {
    background-color: var(--primary-color-hover-eshi, #882900);
    color: var(--text-contrast-primary-eshi, white);
}
.color-primary-hover {
    color: var(--primary-color-eshi, #D14132);
}
.color-primary-hover:hover {
    color: var(--primary-color-hover-eshi, #882900);
}

.on-hover-color-primary:hover {
    color: var(--primary-color-eshi, #D14132);
}

.shadow-primary-hover {
    color: var(--primary-color-eshi, #D14132);
    box-shadow: none;
}
.shadow-primary-hover:hover {
    color: var(--primary-color-eshi, #D14132);
    box-shadow: inset 0 0 0 0.0625rem;
}

.border-primary {
    border: 0.0625rem solid var(--primary-color-eshi, #D14132);
}

a {
    color: var(--primary-color-eshi, #D14132);
}
a:hover {
    color: var(--primary-color-hover-eshi, #882900);
}
